import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import SEO from "@components/SEO"
import "@styles/index.scss"
import { Link } from "gatsby"

const NotFoundPage = ({ location }) => {
  // comment
  return (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      <div style={{ display: "block" }}>
        <SEO seo={{ title: "Page not found" }} />
        are you lost? 404 page not found
        <br />
        <Link to="/">Go back home</Link>
      </div>
    </div>
  )
}

export default NotFoundPage
